<template>
  <div>
    <b-card
      :title="`ฟอร์มกำหนดสิทธิผู้ใช้`"
      class="form-permission"
    >
      <b-row>
        <b-col cols="12">
          <b-row :class="`justify-content-md-center`">
            <b-col
              :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
            >
              <b-form-group
                label-cols-md="2"
                label-cols-sm="3"
                label-align-md="right"
              >
                <template v-slot:label>
                  ประเภทผู้ใช้
                </template>
                <b-form-input
                  id="name"
                  :value="memberTypeName"
                  trim
                  placeholder="ประเภทผู้ใช้"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
            <b-col
              :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
            >
              <b-form-group
                label-cols-md="2"
                label-cols-sm="3"
                label-align-md="right"
              >
                <div class="mt-50">
                  <div>
                    <b-row>
                      <b-col
                        cols="12"
                        md="5"
                      />
                      <b-col
                        cols="12"
                        md="7"
                      >
                        <b-row>
                          <b-col cols="4">
                            <b-form-checkbox
                              v-model="checkboxActionView"
                              :value="1"
                              :unchecked-value="0"
                              class="font-weight-bolder"
                            >
                              แสดงทั้งหมด
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="4">
                            <b-form-checkbox
                              v-model="checkboxActionAddEdit"
                              :value="1"
                              :unchecked-value="0"
                              class="font-weight-bolder"
                            >
                              เพิ่ม / แก้ไขทั้งหมด
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="4">
                            <b-form-checkbox
                              v-model="checkboxActionDelete"
                              :value="1"
                              :unchecked-value="0"
                              class="font-weight-bolder"
                            >
                              ลบทั้งหมด
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="mt-50">
                  <div
                    v-for="(item, index) in dataPermission"
                    :key="index"
                    class="mb-1"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="5"
                      >
                        <span class="font-weight-bolder">{{ Number(index) + 1 }}. {{ item.system_name }}</span>
                      </b-col>
                      <b-col
                        cols="12"
                        md="7"
                      >
                        <b-row>
                          <b-col cols="4">
                            <b-form-checkbox
                              v-model="item.action_view"
                              :value="1"
                              :unchecked-value="0"
                            >
                              แสดง
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="4">
                            <b-form-checkbox
                              v-model="item.action_add_edit"
                              :value="1"
                              :unchecked-value="0"
                            >
                              เพิ่ม / แก้ไข
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="4">
                            <b-form-checkbox
                              v-model="item.action_delete"
                              :value="1"
                              :unchecked-value="0"
                            >
                              ลบ
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div
                      v-for="(item_parent, index_parent) in item.system_parent"
                      :key="index_parent"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="5"
                        >
                          <span class="pl-1">{{ `${Number(index) + 1}.${Number(index_parent) + 1}` }}. {{ item_parent.system_parent_name }}</span>
                        </b-col>
                        <b-col
                          cols="12"
                          md="7"
                        >
                          <b-row>
                            <b-col cols="4">
                              <b-form-checkbox
                                v-model="item_parent.action_view"
                                :value="1"
                                :unchecked-value="0"
                              >
                                แสดง
                              </b-form-checkbox>
                            </b-col>
                            <b-col cols="4">
                              <b-form-checkbox
                                v-model="item_parent.action_add_edit"
                                :value="1"
                                :unchecked-value="0"
                              >
                                เพิ่ม / แก้ไข
                              </b-form-checkbox>
                            </b-col>
                            <b-col cols="4">
                              <b-form-checkbox
                                v-model="item_parent.action_delete"
                                :value="1"
                                :unchecked-value="0"
                              >
                                ลบ
                              </b-form-checkbox>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
            <b-col
              :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
            >
              <b-form-group
                label-cols-md="2"
                label-cols-sm="3"
                label-align-md="right"
              >
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-overlay
                    :show="overlaySubmit"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                  >
                    <b-button
                      variant="primary"
                      class="mr-2"
                      @click="fncSubmit"
                    >
                      <feather-icon icon="SaveIcon" />
                      บันทึกข้อมูล
                    </b-button>
                  </b-overlay>
                  <b-button
                    type="button"
                    variant="outline-secondary"
                    :to="{ path: '/permission' }"
                  >
                    ยกเลิก
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormCheckbox, BFormGroup, BFormInput, BOverlay, BButton,
} from 'bootstrap-vue'
import usePermissionForm from './usePermissionForm'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BOverlay,
    BButton,
  },

  setup() {
    const {
      memberTypeName,

      // variable checkbox
      checkboxActionView,
      checkboxActionAddEdit,
      checkboxActionDelete,

      // variable data
      dataPermission,

      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
      resolveFormatDate,

      // helper
      isLocalServer,
    } = usePermissionForm()

    // vee validate
    // const {
    //   refFormObserver,
    //   getValidationState,
    //   resetForm,
    // } = formValidation()

    return {
      memberTypeName,

      // variable checkbox
      checkboxActionView,
      checkboxActionAddEdit,
      checkboxActionDelete,

      // variable data
      dataPermission,

      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
      resolveFormatDate,

      // helper
      isLocalServer,

      // vee validate
      // refFormObserver,
      // getValidationState,
      // resetForm,
      // required,
    }
  },
}
</script>

<style>
.form-permission .form-row > legend
{
  margin-top: 10px;
}

.form-permission .delete-margin {
  margin-top: -40px;
}
</style>
