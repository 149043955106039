import {
  ref, computed, onMounted, watch,
} from '@vue/composition-api'
import router from '@/router'
import axios from '@axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePermissionForm() {
  const memberTypeId = router.currentRoute.params.id
  const memberTypeName = ref(null)

  // Notification
  const toast = useToast()

  // variable checkbox
  const checkboxActionView = ref(0)
  const checkboxActionAddEdit = ref(0)
  const checkboxActionDelete = ref(0)

  // variable data
  const dataPermission = ref([])

  // loading animation
  const overlaySubmit = ref(false)

  // variable computed
  const comptuedDataPermission = computed(() => {
    const setData = []

    dataPermission.value.forEach(item => {
      setData.push({
        system_name: item.system_alias,
        action_view: item.action_view,
        action_add_edit: item.action_add_edit,
        action_delete: item.action_delete,
      })

      item.system_parent.forEach(itemParent => {
        setData.push({
          system_name: itemParent.system_parent_alias,
          action_view: itemParent.action_view,
          action_add_edit: itemParent.action_add_edit,
          action_delete: itemParent.action_delete,
        })
      })
    })

    return setData
  })

  const fncSubmit = () => {
    overlaySubmit.value = true

    const payload = {
      member_type_id: memberTypeId,
      data_permission: comptuedDataPermission.value,
    }

    console.log('fncSubmit', payload)

    axios
      .post('/Permission', { requestData: payload })
      .then(response => {
        // console.log(resposne.data)
        if (response.data.status) {
          router.push('/permission')

          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'บันทึกข้อมูลกำหนดสิทธิสำเร็จ',
            },
          })
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: 'พบข้อผิดพลาดในการบันทึกข้อมูล',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }

        overlaySubmit.value = false
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาดในการบันทึกข้อมูล [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        overlaySubmit.value = false
      })
  }

  const fncFetchDataEdit = () => {
    axios
      .get(`/Permission/${memberTypeId}`)
      .then(response => {
        // console.log(response.data)
        const { responseData } = response.data

        if (response.data.status) {
          memberTypeName.value = responseData.membertype
          dataPermission.value = responseData.system
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: 'พบข้อผิดพลาด',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาด [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  /* กำหนดรูปแบบ ว/ด/ป */
  /* กำหนดปีเป็น พ.ศ. */
  const toBuddhistYear = (val, format) => {
    const christianYear = val.format('YYYY')
    // eslint-disable-next-line radix
    const buddhishYear = (parseInt(christianYear) + 543).toString()
    return val
      .format(
        format
          .replace('YYYY', buddhishYear)
          .replace('YY', buddhishYear.substring(2, 4)),
      )
      .replace(christianYear, buddhishYear)
  }

  const resolveFormatDate = val => (val
    ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
    : null)

  // helper
  const isLocalServer = computed(() => {
    const getServerName = window.location.host.includes(':') ? window.location.host.split(':')[0] : window.location.host
    return getServerName === 'localhost'
  })

  watch(checkboxActionView, () => {
    // [WATCH] ตรวจสอบกรณีเลือก Select All / Unselect all
    if (checkboxActionView.value) {
      dataPermission.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action_view = 1
        item.system_parent.forEach(itemParent => {
          // eslint-disable-next-line no-param-reassign
          itemParent.action_view = 1
        })
      })
    } else {
      dataPermission.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action_view = 0
        item.system_parent.forEach(itemParent => {
          // eslint-disable-next-line no-param-reassign
          itemParent.action_view = 0
        })
      })
    }
  })

  watch(checkboxActionAddEdit, () => {
    // [WATCH] ตรวจสอบกรณีเลือก Select All / Unselect all
    if (checkboxActionAddEdit.value) {
      dataPermission.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action_add_edit = 1
        item.system_parent.forEach(itemParent => {
          // eslint-disable-next-line no-param-reassign
          itemParent.action_add_edit = 1
        })
      })
    } else {
      dataPermission.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action_add_edit = 0
        item.system_parent.forEach(itemParent => {
          // eslint-disable-next-line no-param-reassign
          itemParent.action_add_edit = 0
        })
      })
    }
  })

  watch(checkboxActionDelete, () => {
    // [WATCH] ตรวจสอบกรณีเลือก Select All / Unselect all
    if (checkboxActionDelete.value) {
      dataPermission.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action_delete = 1
        item.system_parent.forEach(itemParent => {
          // eslint-disable-next-line no-param-reassign
          itemParent.action_delete = 1
        })
      })
    } else {
      dataPermission.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action_delete = 0
        item.system_parent.forEach(itemParent => {
          // eslint-disable-next-line no-param-reassign
          itemParent.action_delete = 0
        })
      })
    }
  })

  onMounted(() => {
    if (memberTypeId) fncFetchDataEdit()
  })

  return {
    memberTypeId,
    memberTypeName,

    // variable checkbox
    checkboxActionView,
    checkboxActionAddEdit,
    checkboxActionDelete,

    // variable data
    dataPermission,

    // loading animation
    overlaySubmit,

    // function
    fncSubmit,
    resolveFormatDate,

    // helper
    isLocalServer,
  }
}
